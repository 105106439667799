.error {
  background-color: #ffc6bd;
}
.errorHeighlight {
  background-color: red;
  font-weight: bold;
}
.header {
  font-weight: bold;
  font-size: 13px;
}
.content {
  font-size: small;
}

.errorBlink {
  color: red;
  animation: blinker 1s step-end infinite;
  display: flex;
  flex: 1;
  align-items: center;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
